import $ from 'jquery'
import './main.scss'

// Special handling for signup form
// We need to send a CompleteRegistration event for facebook pixel tracking
// After that is done, redirect to the appropriate page
// In the rare event of an error (maybe not so rare anymore in case people have the always on captcha setting turned on for their list), submit the form synchronously (fine as subscription isn't created - and even if it was - we will reuse it)
$(document).on('submit', '.signup-form > form:not(.force-sync)', function (e) {
  e.preventDefault()
  const form = $(this)
  form.find('input[type="submit"]').attr('disabled', true)

  if (event.result !== false) {
    const onError = function () {
      form.find('input[name=force_redirect]').remove()
      return form.addClass('force-sync').submit()
    }

    $('<input>')
      .attr({ type: 'hidden', name: 'force_redirect', value: true })
      .appendTo(form)

    const request = {
      type: form.attr('method'),
      url: form.attr('action'),
      data: $(form[0]).serializeArray(),
      dataType: 'JSON',
      beforeSend(xhr) {
        return xhr.setRequestHeader('Accept', 'text/javascript')
      },
      xhrFields: {
        withCredentials: true,
      },
      success(data, _status, _xhr) {
        if (data.error) {
          onError()
          return
        }

        const track = () => {
          if (typeof window.fbq !== 'function' || !data.new) {
            return
          }
          return window.fbq(
            'track',
            'CompleteRegistration',
            { content_name: data.list_name, currency: 'USD', value: 0 },
            { eventID: data.pixel_event_id }
          )
        }

        if (typeof window.simpleroCookieConsent === 'undefined') {
          console.log(
            'Simplero Cookie Consent not found, invoking fb pixel tracking'
          )
          track()
        } else {
          window.simpleroCookieConsent.whenCookiesAllowed(track)
        }

        return (window.location.href = data.redirect_to)
      },
      error: onError,
    }

    return $.ajax(request)
  }
})

$(document).on(
  'simplero:afterComplete',
  '.signup-form > form',
  function (e, form, data) {
    form.find('input[type="submit"]').attr('disabled', true)

    if (data.error) {
      return form.addClass('force-sync').submit()
    } else {
      return console.log(data)
    }
  }
)
